import { useCallback } from 'react';
import { useCountriesForFeature } from '@noah-labs/fe-shared-data-access-countries';
import { useFiatPaymentMethodTokenizedCardSaveMutation } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserDefaultBillingAddress, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, FiatPaymentMethodTokenProvider } from '@noah-labs/shared-schema-gql';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { EnterPaymentInfoScene } from '../../wallet/scenes/EnterPaymentInfo';
import { useUserError } from '../hooks/useUserError';

const cardsQueryId = ['FiatPaymentMethods'];

export function NewPaymentMethod(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const defaultAddress = useUserDefaultBillingAddress();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [[Feature.Buy, Feature.Sell]],
    webConfigBrowser.regions.ProhibitedCountries,
    webConfigBrowser.regions.RestrictedRegions,
  );
  const match = useRouteMatch(userRoutes.settings.newPaymentMethod);
  const queryClient = useQueryClient();
  const pushAlert = usePushAlert();
  const { push } = useRouter();

  const {
    error: saveCardError,
    isLoading: saveCardLoading,
    mutateAsync: saveCard,
  } = useFiatPaymentMethodTokenizedCardSaveMutation();

  const onCardTokenized = useCallback(
    async (cardTokenized: FrameCardTokenizedEvent) => {
      try {
        await saveCard({
          Input: {
            Provider: FiatPaymentMethodTokenProvider.Checkout,
            SaveForFutureUse: true,
            Token: cardTokenized.token,
          },
        });

        await queryClient.invalidateQueries(cardsQueryId);

        pushAlert({
          key: 'saveCardSuccessful',
          message: 'Payment method added',
          severity: 'success',
        });

        push(userRoutes.settings.paymentMethods);
      } catch (err) {
        // handled in useUserError
      }
    },
    [push, pushAlert, queryClient, saveCard],
  );

  const { ApiErrorScene } = useUserError(saveCardError);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterPaymentInfoScene
      countries={countriesData?.countries}
      ctaLabel="Save card"
      defaultAddress={defaultAddress}
      fullName={userData?.userProfile.fullDisplayName}
      isLoading={saveCardLoading}
      isVisible={!!match?.isExact}
      pageTitle="New Payment Method"
      saveToggle={false}
      visiblePath={userRoutes.settings.newPaymentMethod}
      onCardTokenized={onCardTokenized}
    />
  );
}
