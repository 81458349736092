import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import {
  DefaultListItemText,
  DialogSelectField,
  ElevatedCard,
  InputField,
} from '@noah-labs/fe-shared-ui-components';
import { formatStreetAddress } from '@noah-labs/fe-shared-ui-shared';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useFormContext } from 'react-hook-form';
import type { TpBillingAddressForm } from './schema';
import { fieldNames } from './schema';

function isStringEmpty(value: string): boolean {
  return value.trim().length === 0;
}

function isBillingAddressEmpty(billingAddress: TpBillingAddressForm | undefined | null): boolean {
  return (
    !isUndefinedOrNull(billingAddress) &&
    isStringEmpty(billingAddress.Street) &&
    isStringEmpty(billingAddress.City) &&
    isStringEmpty(billingAddress.PostCode) &&
    isStringEmpty(billingAddress.State) &&
    isStringEmpty(billingAddress.CountryCode)
  );
}

export type PpBillingAddressesForm = {
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  readOnly?: boolean;
};

export function BillingAddressesForm({
  countries,
  defaultAddress,
  readOnly = false,
}: PpBillingAddressesForm): React.ReactElement {
  const theme = useTheme();
  const styles = {
    changeBtn: css`
      display: inline;
      text-align: left;
      width: auto;
      min-width: auto;
      padding: 0;
      min-height: auto;
      padding-top: ${theme.spacing(1)};
      color: ${theme.palette.primaryBrand.main};

      :hover {
        background-color: transparent;
        text-decoration: underline;
      }
    `,
    hide: css`
      visibility: hidden;
    `,
  };

  // Defaults to expanded form if read only or no default address
  const [formOpen, setFormOpen] = useState(readOnly || isBillingAddressEmpty(defaultAddress));

  const { setValue, watch } = useFormContext<{ billingAddress: TpBillingAddressForm }>();

  const billingAddress = watch('billingAddress');
  const billingAddressDisplay = formatStreetAddress(billingAddress);

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      setValue('billingAddress.CountryCode', value?.value || '', {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue('billingAddress.CountryName', value?.label || '');
    },

    [setValue],
  );

  useEffect(() => {
    if (!defaultAddress) {
      return;
    }

    setValue('billingAddress', defaultAddress, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [defaultAddress, setValue]);

  if (formOpen) {
    return (
      <Stack spacing={2.5}>
        <InputField
          fullWidth
          dataQa={fieldNames.Street}
          InputProps={{
            readOnly,
          }}
          label="Address line 1"
          name={fieldNames.Street}
        />
        <InputField
          fullWidth
          dataQa={fieldNames.Street2}
          InputProps={{
            readOnly,
          }}
          label="Address line 2 (optional)"
          name={fieldNames.Street2}
        />
        <InputField
          fullWidth
          dataQa={fieldNames.City}
          InputProps={{
            readOnly,
          }}
          label="City"
          name={fieldNames.City}
        />
        <InputField
          fullWidth
          dataQa={fieldNames.State}
          InputProps={{
            readOnly,
          }}
          label="County / State"
          name={fieldNames.State}
        />
        <DialogSelectField
          disabled={readOnly}
          isFetched={!!countries}
          label="Country"
          name={fieldNames.CountryName}
          options={countries}
          onChange={handleCountryFormValues}
        />
        <InputField
          fullWidth
          dataQa={fieldNames.PostCode}
          InputProps={{
            readOnly,
          }}
          label="Post / Zip Code"
          name={fieldNames.PostCode}
        />
      </Stack>
    );
  }

  return (
    <ElevatedCard sx={{ p: 2 }}>
      <DefaultListItemText
        secondary={billingAddressDisplay}
        secondaryTypographyProps={{ sx: { whiteSpace: 'break-spaces' } }}
      />
      <Button css={styles.changeBtn} size="small" onClick={(): void => setFormOpen(true)}>
        Change address
      </Button>
    </ElevatedCard>
  );
}
