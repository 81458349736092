import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid, Link, Stack } from '@mui/material';
import type { SocialProvider } from '@noah-labs/fe-shared-data-access-auth';
import { PasswordFormItem, SocialAuthButton, useAuthError } from '@noah-labs/fe-shared-ui-auth';
import {
  AppContainer,
  AppHeader,
  AppLogo,
  AppMain,
  FormItem,
  InputField,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { toTitleCase } from '@noah-labs/shared-util-vanilla';
import { Helmet } from 'react-helmet';
import type { ErrorOption, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { orderedProviders } from '../constants';

const signUpSchema = yup.object({
  email: yup
    .string()
    .required('Email is a required field.')
    .email('Email must be a valid email address.'),
  password: yup
    .string()
    .required('Password is a required field.')
    .min(8, 'Password must be at least 8 characters.'),
});

export type TpSignUpForm = {
  email: string;
  password: string;
  root?: { serverError: void };
};

export type TpOnSignUp = (
  values: TpSignUpForm,
  setError: UseFormSetError<TpSignUpForm>,
) => Promise<void>;
export type TpOnSocialSignUp = (
  provider: SocialProvider,
  setError: UseFormSetError<TpSignUpForm>,
) => Promise<void>;

export type PpSignUpScene = {
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  onSignUp: TpOnSignUp;
  onSocialSignUp: TpOnSocialSignUp;
  signinUrl: string;
};

export function SignUpScene({
  error,
  helpButton,
  onSignUp,
  onSocialSignUp,
  signinUrl,
}: PpSignUpScene): React.ReactElement {
  const methods = useForm<TpSignUpForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(signUpSchema),
  });

  const formId = 'sign-up-form';

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <AppContainer>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <AppMain>
        <AppHeader endIconsSlot={helpButton}>
          <AppLogo />
        </AppHeader>
        <SceneHeader textAlign="center">
          <SceneTitleLarge>Sign up</SceneTitleLarge>
          <SceneParagraph>
            Already have an account? <Link href={signinUrl}>Log in</Link>
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="sign-up">
          <Stack justifyContent="center" spacing={4}>
            <Stack spacing={2}>
              <FormProvider {...methods}>
                <form id={formId} onSubmit={handleSubmit((values) => onSignUp(values, setError))}>
                  <Grid container spacing={1}>
                    <FormItem fullWidth>
                      <InputField
                        fullWidth
                        required
                        autoComplete="username"
                        dataQa="email"
                        inputProps={{
                          'aria-label': 'email',
                        }}
                        name="email"
                        placeholder="Email address*"
                        type="email"
                      />
                    </FormItem>
                    <PasswordFormItem />
                  </Grid>
                </form>
              </FormProvider>
              <PrimaryButton
                color="primaryBrand"
                disabled={isSubmitting}
                form={formId}
                loading={isSubmitting}
                type="submit"
              >
                Sign up
              </PrimaryButton>
            </Stack>
            <Divider>Or</Divider>
            <Stack spacing={1}>
              {orderedProviders.map((p) => (
                <SocialAuthButton
                  key={p.name}
                  icon={p.icon}
                  label={`Sign up with ${toTitleCase(p.name)}`}
                  onClick={(): Promise<void> => onSocialSignUp(p.name, setError)}
                />
              ))}
            </Stack>
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
