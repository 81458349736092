import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { Balance, generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode, useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { CompleteScene, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { getConfig } from '../../config';
import { useInAppReviewTrigger } from '../../hooks/useInAppReviewTrigger';
import type { SmLightningSend } from '../types';

export function LightningSendCompleteController({
  resetState,
  state,
}: TpStateMachine<SmLightningSend>): React.ReactElement {
  const { enableLoaderAnimations } = getConfig();

  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { data: userData } = useUserInitUi();
  const { useOnRouteChange } = useRouter();

  /**
   * End of the flow so reset the flow state machine
   */
  useOnRouteChange(resetState);

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount: state.fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
  });

  const paymentHash = state.paymentRequestData?.paymentHash;

  const enableInAppReviewTrigger = !!paymentHash;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  return (
    <CompleteScene
      BalanceSlot={<Balance {...Amounts} />}
      enableAnimation={enableLoaderAnimations}
      processingTimeMs={state.paymentRequestData?.processingTime}
      transactionUrl={generatePath(walletRoutes().account.base, params)}
    />
  );
}
