import React, { useCallback, useState } from 'react';
import {
  assignLocationAndWait,
  AuthErrorType,
  getIsWebAuthnSupported,
  useOry,
  useOrySignInCallback,
  useOrySignInFlowError,
  useOrySocialSignInCallback,
  useOryWebAuthnSignInCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import type { TpSignInState } from '@noah-labs/fe-shared-ui-auth';
import { useSearchParams } from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { logger } from '@noah-labs/shared-logger/browser';
import type { AwsCaptchaApi } from '@noah-labs/shared-schema-gql';
import type { UseFormSetError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAwsCaptcha } from '../hooks/useAwsCaptcha';
import { useEmailForAutocomplete } from '../hooks/useEmailForAutocomplete';
import { useOryHasSecurityKeyCallback } from '../hooks/useOryHasSecurityKeyCallback';
import type { TpSignInForm } from '../scenes/SignInScene';
import { SignInScene } from '../scenes/SignInScene';
import { handleLoginFlowError } from '../utils/handleLoginFlowError';

export type PpSignIn = {
  captcha: AwsCaptchaApi | undefined;
  helpButton?: React.ReactNode;
};

export function SignIn({ captcha, helpButton }: PpSignIn): React.ReactElement {
  const { returnTo } = useOry();
  const { state } = useLocation<{ from: string } | undefined>();
  const searchParams = useSearchParams();
  const externalRedirectUrl = searchParams?.get('redirectUrl');
  const pathnameFromState = state?.from;
  const redirectTo = pathnameFromState
    ? new URL(pathnameFromState, window.location.origin).toString()
    : externalRedirectUrl || returnTo;

  const onPasswordSignIn = useOrySignInCallback();
  const onSocialSignIn = useOrySocialSignInCallback(redirectTo);
  const onWebAuthnSignIn = useOryWebAuthnSignInCallback();

  const getHasSecurityKey = useOryHasSecurityKeyCallback();

  const flowError = useOrySignInFlowError();

  const [sceneState, setSceneState] = useState<TpSignInState>(
    getIsWebAuthnSupported() ? 'initial' : 'password',
  );

  const { autocompleteEmail, onUpdateAutocomplete } = useEmailForAutocomplete();
  const { captchaCallback } = useAwsCaptcha(captcha);

  const onSignIn = useCallback(
    async (values: TpSignInForm, setError: UseFormSetError<TpSignInForm>) => {
      onUpdateAutocomplete(null);

      async function onSuccess(): Promise<void> {
        onUpdateAutocomplete(values.email);
        await assignLocationAndWait(redirectTo);
      }

      if (sceneState === 'password') {
        const captchaSucceed = await captchaCallback();

        if (!captchaSucceed) {
          setError('root.serverError', {
            message: 'CAPTCHA verification failed.',
            type: AuthErrorType.Unspecified,
          });
          return;
        }

        try {
          await onPasswordSignIn(values);
          await onSuccess();
        } catch (e) {
          logger.error(e);
          handleLoginFlowError(e, setError);
        }
        return;
      }

      if (sceneState === 'webauthn') {
        try {
          await onWebAuthnSignIn(values);
          await onSuccess();
        } catch (e) {
          logger.error(e);
          handleLoginFlowError(e, setError);
        }
        return;
      }

      let hasSecurityKey = false;
      try {
        hasSecurityKey = await getHasSecurityKey(values.email);
      } catch (e) {
        logger.error('failed checking security key', e);
      }

      setSceneState(hasSecurityKey ? 'webauthn' : 'password');
      if (hasSecurityKey) {
        try {
          await onWebAuthnSignIn(values);
          await onSuccess();
        } catch (e) {
          logger.error(e);
          handleLoginFlowError(e, setError);
        }
      }
    },
    [
      onUpdateAutocomplete,
      getHasSecurityKey,
      onWebAuthnSignIn,
      onPasswordSignIn,
      captchaCallback,
      sceneState,
      redirectTo,
    ],
  );

  return (
    <SignInScene
      autocompleteEmail={autocompleteEmail}
      error={flowError}
      getHasSecurityKey={getHasSecurityKey}
      helpButton={helpButton}
      sceneState={sceneState}
      signupUrl={authRoutes.signUp}
      onGoToPasswordLogin={(): void => {
        setSceneState('password');
      }}
      onSignIn={onSignIn}
      onSocialSignIn={onSocialSignIn}
    />
  );
}
