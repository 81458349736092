import { useEffect } from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { SmWithdraw } from '@noah-labs/fe-shared-feature-wallet';
import {
  WithdrawCompleteController,
  WithdrawConfirmController,
  WithdrawEnterAmountController,
  WithdrawNetworkController,
} from '@noah-labs/fe-shared-feature-wallet';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  TpAuthStatus,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { KycAccessControlData } from '../../../kyc/controllers/KycAccessControlData';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { useSardineFlow } from '../../../sardine/hooks/useSardineFlow';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';
import { useAddress } from '../../hooks/useAddress';
import { useSendAmounts } from '../../hooks/useSendAmounts';

const emptyState: SmWithdraw = {
  cryptoAmount: '',
  fetchedAt: '',
  fiatAmount: '',
  payeeData: undefined,
  price: '',
  withdrawOrderId: '',
};

export function WithdrawRouter(): React.ReactElement {
  const { push } = useRouter();
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();
  /**
   * Remove destination address from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      payeeData: data,
    };
  }

  const sm = useStateMachine<SmWithdraw>({
    emptyState,
    initialState,
    name: 'Withdraw',
  });

  const { data: userData } = useUserInitUi();
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const { fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: sm.state.cryptoAmount,
    fiatAmount: sm.state.fiatAmount,
  });

  const { updateState } = sm;
  useEffect(() => {
    updateState({
      fetchedAt,
      fiatAmount,
      price,
    });
  }, [fetchedAt, fiatAmount, price, updateState]);

  // if user hasn't scanned or enter an address, we have to go back to the scanner
  const addressInvalidRedirect = !sm.state.payeeData?.address;

  // if any data is missing, we can't show confirm or complete screens
  const confirmInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;
  const completeInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;

  return (
    <Switch404>
      <Route exact path={walletRoutes().withdraw.network}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect && generatePath(walletRoutes().address.text, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper headTitle="Select network">
              <WithdrawNetworkController
                {...sm}
                cryptoCurrency={cryptoCurrency}
                userFeatures={userData?.userProfile.features}
                onNext={(): void => push(generatePath(walletRoutes().withdraw.enterAmount, params))}
              />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().withdraw.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect && generatePath(walletRoutes().address.text, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper headerTitle="Enter Send Amount" headTitle="Enter Send Amount">
              <WithdrawEnterAmountController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().withdraw.confirm}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            confirmInvalid && generatePath(walletRoutes().withdraw.enterAmount, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper
              headerTitle="Confirm Transaction"
              headTitle="Confirm Transaction"
            >
              <WithdrawConfirmController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().withdraw.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            completeInvalid && generatePath(walletRoutes().withdraw.enterAmount, params)
          }
        >
          <WalletControllerWrapper backButton={false} headTitle="Processing Withdrawal">
            <WithdrawCompleteController {...sm} />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
