import { AppContainer, AppHeaderTitle } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';

type PpWalletControllerWrapper = PpWC & {
  backButton?: boolean;
  headTitle: string;
  headerTitle?: string;
};

export function WalletControllerWrapper({
  backButton = true,
  children,
  headerTitle,
  headTitle,
}: PpWalletControllerWrapper): React.ReactElement {
  return (
    <AppContainer headTitle={headTitle}>
      <AppHeaderData exitButton helpButton backButton={backButton}>
        {headerTitle && <AppHeaderTitle>{headerTitle}</AppHeaderTitle>}
      </AppHeaderData>
      {children}
    </AppContainer>
  );
}
